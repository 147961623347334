<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header :headerName="'巴林右旗' + `${townName + villageName + groupName}` + '防疫统计'" @selectJob="selectJob" />

    <div class="content_box w_full h_full-50 flex_row_around p_10 border_box">
      <!-- 1 -->
      <div class="w_32_5_p h_full flex_column_between">
        <mian-yi-yi-miao-chart
          :areaCode="formatGroupAreaCode(villageId, groupId)" 
          :level="level"
          countyId="150423000000"
          :townId="townId"
          :villageId="villageId"
          :groupId="groupId"
          :jobId="jobId"
          @changeCount="changeCount"
        />
      </div> 

      <!-- 2 -->
      <div class="w_32_5_p h_full flex_column_between">
        <div class="w_full h_49_p bgs_full chart_box_bgi_1 relative">
          
          <ta-ben-hua v-if="villageName == '塔本花嘎查'" />
          <span v-else class="main_color absolute_center text_1">暂无地区图片</span>
        </div>

        <!-- 文字描述 -->
        <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_5 color_fff text_1">
          <div class="w_full flex_row_between p_10">
            <div class="text_1 jian_bian_text_1 fw_bold"> 
              {{ groupName }}存栏数量：<span class="font_number theme_color_orange">
                <animate-number from="0" :to="animalCount" :key="animalCount" duration="3000" />
              </span> 
            </div>

            <div class="text_1 jian_bian_text_1 fw_bold"> 
              {{ groupName }}免疫数量：<span class="font_number theme_color_green">
                <animate-number from="0" :to="fangYiCount" :key="fangYiCount" duration="3000" />
              </span> 
            </div>
          </div>
          
          <!-- xxx 小组描述 -->
          <div class="w_full h_full-70 text_indent_2 ph_10 overflow_y_auto scroll_box">
            {{ content }}
          </div>
        </div>
      </div> 

      <!-- 3 -->
      <div class="w_32_5_p h_full p_10 border_box bgs_full chart_box_bgi_2">
        <div class="w_full flex_start flex_center">
          <div class="w_40_p jian_bian_text_1 fw_bold font_number text_left">
            牧户总数：{{ muhuList.length }}
          </div>
          <div class="w_40_p pl_10">
            <!-- <el-input 
              v-model="queryParams.userName" 
              size="small" clearable filterable
              placeholder="牧户" 
              @input="inputName"
              @clear="clearName"
            /> -->
            <el-select 
              class="w_full_i" 
              v-model="muHuId" size="small" clearable 
              placeholder="牧户" filterable
              @change="selectMuHu"
              @clear="clearName()"
            >
              <el-option
                v-for="item in muHuNameList" :key="item.userId"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div class="w_40_p pl_10">
            <el-input 
              v-model="queryParams.phone" 
              size="small" clearable filterable
              placeholder="电话" 
              @input="inputPhone"
              @clear="clearPhone"
            />
          </div>
        </div>

        <div class="scroll_box w_full h_full-50 overflow_y_auto mt_10">
          <div
            class="w_full h_20_p p_10 border_box flex_row_between mb_10 hover_main_border" 
            v-for="(item, index) in muhuList" :key="index"
            @click="handleClickMuhu(item.id)"
          >
            <!-- 头像 -->
            <div class="w_30_p p_5 border_radius_10">
              <img 
                class="w_full border_radius_10 cursor response_img" 
                :src="filePath + item.avatarUrl || def_user_img" alt=""
                v-if="item.avatarUrl"
              >
              <img 
                class="w_80_p border_radius_10 cursor response_img" 
                :src="def_user_img" alt=""
                v-else
              >
            </div>

            <!-- 文字信息 -->
            <div class="info_box w_70_p flex_column_start pl_20 cursor">
              <div class="text_1 color_fff">牧户姓名: <span class="main_color">{{ item.nickName || ''}}</span></div>
              <div class="text_1 color_fff">电话: <span class="font_number">{{ item.phone || ''}}</span></div>
              <!-- <div class="text_1 color_fff">性别: {{ formatGender(item.gender) || ''}} </div> -->
              <div class="text_1 color_fff">其他: {{ item.address || ''}} </div>
            </div>
          </div>
        </div>  
      </div> 
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def.js' 
  import componentsMixin from '@/mixins/components.js'
  import geoJsonMapMixin from '@/mixins/geoJson-map.js'
  import imgMixin from '@/mixins/def-img.js'
  import dataChartMixin from '@/mixins/data-charts.js'

  import{ getUserList } from '@/api/mu-hu/mu-hu.js';

  export default {
    name: 'GroupPage',
    mixins: [defMixin, componentsMixin, geoJsonMapMixin, imgMixin, dataChartMixin],
    data() {
      return {
        animalCount: null,
        fangYiCount: null,

        jobId: null,

        townId: null,
        villageId: null,
        townName: null,
        villageName: null,
        level: null,
        groupId: null,
        groupName: null,

        content: sessionStorage.getItem('groupContent'),

        queryParams: {
          userName: '',
          phone: ''
        },

        muhuList: [],
        muHuNameList: [],
        targetList: []
      }
    },
    mounted() {
      this.townId = this.$route.params.townId
      this.townName = this.$route.params.townName
      this.level = this.$route.params.level
      this.villageId = this.$route.params.villageId
      this.villageName = this.$route.params.villageName
      this.groupId = this.$route.params.groupId
      this.groupName = this.$route.params.groupName


      // 获取 小组中的 所有 牧户列表
      this.getUserList(this.villageId, this.groupName)
    },
    methods: {
      selectJob(jobId) {
        this.jobId = jobId;
      },
      
      // 监听 地区的存栏防疫 总数
      changeCount(obj) {
        this.animalCount = obj.animalCount
        this.fangYiCount = obj.fangYiCount
      },

      // 获取 小组 中的 牧户列表
      async getUserList(villageId, groupName) {
        let res = await getUserList(villageId, groupName)
        try {
          if (res.code == 1000) {
            this.targetList = res.data
            this.muhuList = res.data

            let muHuNameList = []
            res.data.map(item => {
              muHuNameList.push({ id: item.id, name: item.nickName})
            })

            this.muHuNameList = Array.from(new Set(muHuNameList.map(JSON.stringify)), JSON.parse)
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 跳转到牧户详情页
      handleClickMuhu(id) {
        this.goToMuHuPage(this.townId, this.villageId, this.groupId, id)
      },

      // 格式化 areaCode
      formatGroupAreaCode(villageId, groupId) {
        let code = ''

        // 不足 6 位， 补 0
        let zero_num = 6 - groupId.length

        if (zero_num == 1) {
          code = '0' + groupId.toString()
        } else if (zero_num == 2) {
          code = '00' + groupId.toString()
        } else if (zero_num == 3) {
          code = '000' + groupId.toString()
        } else if (zero_num == 4) {
          code = '0000' + groupId.toString()
        } else if (zero_num == 5) {
          code = '00000' + groupId.toString()
        } else {
          code = groupId
        }

        return villageId + '-' + code
      },

      selectMuHu(userId) {
        this.muhuList = this.targetList.filter(item => item.id == userId)
      },

      clearName() {
        this.muhuList = this.targetList
      },

      inputPhone(val) {
        if (!val) {
          this.muhuList = this.targetList
        }
        this.muhuList = this.targetList.filter(item => item.phone == val)
      },

      clearPhone() {
        this.muhuList = this.targetList
      }

    }
  }
</script>